import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "./Button";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

export function Code({ text, copyButtonText }: { text: string; copyButtonText?: string }) {
  const { t } = useTranslation();

  return (
    <div className="p-3 border rounded-md bg-secondary/50 font-mono flex justify-between items-center space-x-3">
      <pre className="text-wrap break-all text-sm">{text}</pre>
      <CopyToClipboard text={text} onCopy={() => toast.success(t("common_copy_success", "Copied to clipboard!"))}>
        <Button variant="outline" size="sm">
          {copyButtonText || t("common_button_copy", "Copy")}
        </Button>
      </CopyToClipboard>
    </div>
  );
}
