import { ApiObjects } from "@pulso/api-client";
import { addDays, endOfDay } from "date-fns";
import { atom, useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type BookingsFilter = ReturnType<typeof useBookingsFilter>;

const now = new Date();
const rangeAtom = atom(6);
const dateAtom = atom(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)); // start of the day
const dateToAtom = atom((get) => endOfDay(addDays(get(dateAtom), get(rangeAtom))));
const searchTermAtom = atom("");
const statusAtom = atomWithStorage<Record<ApiObjects["BookingDto"]["status"], boolean>>(
  "pulso-bookings-filter-status",
  {
    UNCONFIRMED: true,
    PENDING: true,
    IN_PROGRESS: true,
    COMPLETED: true,
    ON_HOLD: false,
    CANCELLED: false,
  }
);
const pageAtom = atom(1);

export function useBookingsFilter() {
  const [date, setDate] = useAtom(dateAtom);
  const dateTo = useAtomValue(dateToAtom);
  const range = useAtomValue(rangeAtom);
  const [searchTerm, setSearchTerm] = useAtom(searchTermAtom);
  const [statuses, setStatuses] = useAtom(statusAtom);
  const [page, setPage] = useAtom(pageAtom);

  function toggleStatus(status: ApiObjects["BookingDto"]["status"]) {
    setStatuses({ ...statuses, [status]: !statuses[status] });
  }

  return {
    date,
    dateTo,
    range,
    searchTerm,
    statuses,
    page,
    pageSize: 25,

    setDate,
    setSearchTerm,
    toggleStatus,
    setPage,

    prevDate: () => setDate(new Date(date.getTime() - 24 * 60 * 60 * 1000)),
    nextDate: () => setDate(new Date(date.getTime() + 24 * 60 * 60 * 1000)),
    prevPage: () => setPage(page - 1),
    nextPage: () => setPage(page + 1),
    nextRange: () => setDate(addDays(date, range + 1)),
    prevRange: () => setDate(addDays(date, -range - 1)),
  };
}
