import { Button } from "@/components/ui/button";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { useTranslation } from "react-i18next";
import { useUserCreate } from "@/api/useUserCreate";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { Building2, EditIcon, Key, Trash2Icon } from "lucide-react";
import { useUserDelete } from "@/api/useUserDelete";
import { useUserUpdate } from "@/api/useUserUpdate";
import { useUsers } from "@/api/useUsers";
import { UserFormDialog } from "./UserFormDialog";
import { withFacilityIdParam } from "@/lib/withParams";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useUserRoleLabel } from "./useUserRoleLabel";
import { useUserRights } from "@/api/useUserRights";
import keyBy from "lodash/keyBy";
import { ApiObjects } from "@pulso/api-client";
import { Switch } from "@/components/ui/switch";
import { useUserRightEnable } from "@/api/useUserRightEnable";
import { useUserRightDisable } from "@/api/useUserRightDisable";
import { useSendPasswordResetLink } from "@/api/useUserSendPasswordResetLink";
import { SwitchAutosave } from "@/components/ui/switch-autosave";
import { useLanguageLabel } from "./useLanguageLabel";
import sortBy from "lodash/sortBy";
import { UserFacilitiesDialog } from "./UserFacilitiesDialog";
import { useMe } from "@/api/useMe";
import { useMeFacilities } from "@/api/useMeFacilities";
import { UserRole } from "@/components/specific/UserRole";

export const UsersSettingsPage = withFacilityIdParam(({ facilityId }) => {
  const { t } = useTranslation();
  const { user: me } = useMe();
  const { facilities: myFacilities } = useMeFacilities();
  const hasMoreThanOneFacility = myFacilities.length > 1;
  const { users, isLoading } = useUsers(facilityId);
  const usersSorted = sortBy(users, ["role", "firstname", "lastname"]);
  const createMutation = useUserCreate(facilityId);
  const updateMutation = useUserUpdate();
  const deleteMutation = useUserDelete();
  const sendPasswordResetLinkMutation = useSendPasswordResetLink();
  const languageLabels = useLanguageLabel();

  if (isLoading) {
    return <CentralSpinner />;
  }

  return (
    <div className="grid gap-6">
      <Card>
        <CardContent className="pt-6">
          <div>
            <UserFormDialog
              title={t("settings_users_createModal_title", "Add a new user")}
              description={t(
                "settings_users_createModal_description",
                "Add a new user which can be used to modify the prices of a product for a specific period of the year"
              )}
              users={users}
              initialData={{ email: "", firstname: "", lastname: "", language: "en", role: "USER" }}
              onSubmit={(values) => createMutation.mutateAsync(values).catch((e) => Promise.resolve(e))}
            >
              <Button size="sm">{t("settings_users_create_button", "Add user")}</Button>
            </UserFormDialog>
          </div>
          {users.length > 0 && (
            <Table className="mt-3">
              <TableHeader>
                <TableRow>
                  <TableHead>{t("settings_users_tableHeader_name", "Name")}</TableHead>
                  <TableHead>{t("settings_users_tableHeader_email", "Email")}</TableHead>
                  <TableHead>{t("settings_users_tableHeader_langauge", "Language")}</TableHead>
                  <TableHead>{t("settings_users_tableHeader_role", "Role")}</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {usersSorted.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      {user.firstname} {user.lastname}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{languageLabels[user.language]}</TableCell>
                    <TableCell>
                      <UserRole role={user.role} />
                    </TableCell>
                    <TableCell align="right">
                      <div className="flex items-center justify-end">
                        {me?.id !== user.id && hasMoreThanOneFacility ? (
                          <UserFacilitiesDialog user={user}>
                            <Button variant="ghost">
                              <Building2 strokeWidth={1.2} />
                            </Button>
                          </UserFacilitiesDialog>
                        ) : (
                          <Button variant="ghost" className="invisible">
                            <Building2 strokeWidth={1.2} />
                          </Button>
                        )}
                        <ConfirmDialog
                          title={t("settings_users_resetPasswordModal_title", "Reset password")}
                          description={t(
                            "settings_users_resetPasswordModal_description",
                            "Do you want to send a password reset link to {{email}}. They will receive an email with a link where they will be able to create a new password. This will not block their current password until they use the link from the email to create a new one.",
                            { email: user.email }
                          )}
                          onContinue={() => sendPasswordResetLinkMutation.mutate(user.id)}
                        >
                          <Button variant="ghost">
                            <Key strokeWidth={1.2} />
                          </Button>
                        </ConfirmDialog>
                        <UserFormDialog
                          title={t("settings_users_editModal_title", "Update user")}
                          description={t("settings_users_editModal_description", "Update the data of {{name}}", {
                            name: `${user.firstname} ${user.lastname}`,
                          })}
                          users={users.filter((s) => s.id !== user.id)}
                          initialData={{
                            firstname: user.firstname,
                            lastname: user.lastname,
                            email: user.email,
                            language: user.language,
                            role: user.role,
                          }}
                          onSubmit={(values) => updateMutation.mutateAsync({ id: user.id, ...values })}
                        >
                          <Button variant="ghost">
                            <EditIcon strokeWidth={1.2} />
                          </Button>
                        </UserFormDialog>
                        {me?.id !== user.id ? (
                          <ConfirmDialog
                            title={t("settings_users_deleteModal_title", "Delete user")}
                            description={t(
                              "settings_users_deleteModal_description",
                              "Are you sure you want to delete this user?"
                            )}
                            onContinue={() => deleteMutation.mutate(user.id)}
                          >
                            <Button variant="ghost">
                              <Trash2Icon strokeWidth={1.2} />
                            </Button>
                          </ConfirmDialog>
                        ) : (
                          <Button variant="ghost" className="invisible">
                            <Trash2Icon strokeWidth={1.2} />
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      <UserRightsSettings facilityId={facilityId} />
    </div>
  );
});

function UserRightsSettings(props: { facilityId: string }) {
  const { t } = useTranslation();
  const { rights, isLoading } = useUserRights(props.facilityId);
  const index = keyBy(rights, (right) => `${right.right}:${right.role}`);
  const roleLabels = useUserRoleLabel();
  const rightsLabels: Record<ApiObjects["UserRightDto"]["right"], string> = {
    deleteBooking: t("common_userRight_deleteBooking", "Delete bookings"),
    seeReports: t("common_userRight_seeReports", "See reports"),
  };
  const enable = useUserRightEnable(props.facilityId);
  const disable = useUserRightDisable(props.facilityId);

  if (isLoading) {
    return <CentralSpinner />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("settings_userRights_title", "User Rights")}</CardTitle>
        <CardDescription>
          {t("settings_userRights_description", "Define the rights that your staff has")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>
              {Object.entries(roleLabels).map(([role]) => (
                <TableHead key={role}>{<UserRole role={role as ApiObjects["UserDto"]["role"]} />}</TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.entries(rightsLabels).map(([right, rightLabel]) => (
              <TableRow key={right}>
                <TableCell>{rightLabel}</TableCell>
                {Object.entries(roleLabels).map(([role]) => (
                  <TableCell key={role}>
                    {role === "ADMIN" ? (
                      <Switch checked disabled />
                    ) : (
                      <SwitchAutosave
                        checked={!!index[`${right}:${role}`]}
                        onAttempt={(newValue) => {
                          if (newValue === true) {
                            return enable.mutateAsync(right as ApiObjects["UserRightDto"]["right"]);
                          } else {
                            return disable.mutateAsync(right as ApiObjects["UserRightDto"]["right"]);
                          }
                        }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
