import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PropsWithChildren, memo, useCallback, useDeferredValue, useEffect, useState } from "react";
import { ApiObjects } from "@pulso/api-client";
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { UserCard } from "@/components/ui/user-card";
import { useNavigate } from "react-router-dom";
import { useBookingCreate } from "@/api/useBookingCreate";
import { t } from "i18next";
import { useCustomers } from "@/api/useCustomers";
import { cn } from "@/lib/utils";
import { useCustomerForm } from "./details/useCustomerForm";
import { PulsoForm, PulsoFormProvider } from "@/components/specific/Form";
import { useFormContext } from "react-hook-form";

const CustomerFormMemo = memo(CustomerForm);

export function NewBookingModal({
  initialData,
  language,
  children,
}: PropsWithChildren<{
  initialData?: { firstname: string; lastname: string; fields?: Record<string, string> } | null;
  language: string;
}>) {
  const [names, setNames] = useState({ firstname: "", lastname: "" });
  const [fields, setFields] = useState<Record<string, string>>({});
  const [searchRaw, setSearch] = useState("");
  const search = useDeferredValue(searchRaw);
  const [open, setOpen] = useState(!!initialData);
  const { customers } = useCustomers(search);
  const navigate = useNavigate();
  const [selectedCustomer, setSelectedCustomer] = useState<string | undefined>();

  useEffect(() => {
    if (initialData) {
      setOpen(true);
      setNames({
        firstname: initialData.firstname,
        lastname: initialData.lastname,
      });
      setSearch(`${initialData.firstname} ${initialData.lastname}`);
      if (initialData.fields) {
        setFields(initialData.fields);
      }
    }
  }, [initialData]);

  const create = useBookingCreate();
  const onSubmit = useCallback(
    (data: ApiObjects["CreateBookingBody"]) =>
      create.mutate(
        {
          customerId: data.customerId,
          fields: data.fields,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email || undefined,
          language: data.language,
        },
        { onSuccess }
      ),
    []
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent onClick={() => setSearch("")} hideFooter>
        <DialogHeader>
          <DialogTitle>{t("bookings_add_customer_title", "Select customer")}</DialogTitle>
          <DialogDescription>
            {t("bookings_add_customer_subtitle", "Choose the customer who is booking")}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col space-y-3 overflow-y-auto">
          <div className="relative">
            <div className="h-9">
              <div className="absolute w-full z-50">
                <Command
                  shouldFilter={false}
                  value={""}
                  onValueChange={setSelectedCustomer}
                  onClick={() => onSelect()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (selectedCustomer) {
                        onSelect();
                      } else if (search.length) {
                        onCreateNew();
                        setSearch("");
                      }
                    }
                  }}
                >
                  <CommandInput
                    placeholder={t("common_placeholder_search")}
                    value={searchRaw}
                    onValueChange={setSearch}
                  />
                  <CommandList className={cn("shadow-xl", search.length > 2 && "border")}>
                    {search.length > 2 && (
                      <CommandEmpty>
                        <div className="p-3 text-sm text-muted-foreground" onClick={onCreateNew}>
                          {t("bookings_create_customerNotFound", "No customer found. Create a new one?")}
                        </div>
                      </CommandEmpty>
                    )}
                    {customers.map((customer) => (
                      <CommandItem key={customer.id} value={customer.id} className="h-12 cursor-pointer">
                        <UserCard className="flex-1" user={customer} />
                      </CommandItem>
                    ))}
                  </CommandList>
                </Command>
              </div>
            </div>
          </div>
          <div>{t("common_or", "or")}</div>
          <div>{t("bookings_add_newCustomer_title", "Create a new customer")}</div>
          <CustomerFormMemo
            names={names}
            fields={fields}
            language={language}
            onSubmit={onSubmit}
            isLoading={create.isPending}
          />
        </div>
      </DialogContent>
    </Dialog>
  );

  function onSelect() {
    const customer = customers.find((c) => c.id === selectedCustomer);
    if (customer) {
      create.mutate(
        {
          customerId: customer.id,
          firstname: customer.firstname,
          lastname: customer.lastname,
          language: customer.language,
          fields: [],
        },
        {
          onSuccess,
        }
      );
    }
  }

  function onCreateNew() {
    const [firstname, ...lastnameParts] = search.split(" ");
    setNames({ firstname, lastname: lastnameParts.join(" ") });
  }

  function onSuccess(booking: ApiObjects["BookingDto"]) {
    setOpen(false);
    navigate(`./${booking.id}`);
  }
}

function CustomerForm({
  onSubmit,
  names,
  language,
  fields,
  isLoading,
}: {
  onSubmit: (data: ApiObjects["CreateBookingBody"]) => void;
  names: { firstname: string; lastname: string };
  language: string;
  fields?: Record<string, string>;
  isLoading: boolean;
}) {
  const formDef = useCustomerForm();

  return (
    <div className="lg:max-h-96 overflow-y-auto" style={{ padding: "1px" }}>
      <PulsoFormProvider
        {...formDef}
        onSubmit={onSubmit}
        initialValues={{
          firstname: names.firstname,
          lastname: names.lastname,
          email: "",
          language,
          ...(fields || {}),
        }}
        dontAutoRenderForm
        className="flex flex-col h-full"
        fieldsClassName="flex-1 overflow-y-auto"
        isLoading={isLoading}
      >
        <CustomerFormInner names={names} fields={fields} />
      </PulsoFormProvider>
    </div>
  );
}

function CustomerFormInner(props: { names: { firstname: string; lastname: string }; fields?: Record<string, string> }) {
  const form = useFormContext();
  useEffect(() => {
    form.setValue("firstname", props.names.firstname);
    form.setValue("lastname", props.names.lastname);
  }, [props.names]);

  useEffect(() => {
    if (props.fields) {
      Object.entries(props.fields).forEach(([key, value]) => {
        form.setValue(key, value);
      });
    }
  }, [props.fields]);
  return <PulsoForm />;
}
