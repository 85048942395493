import { useBooking } from "@/api/useBooking";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Spinner } from "@/components/ui/spinner";
import { ApiObjects } from "@pulso/api-client";
import { Link, useParams } from "react-router-dom";
import { BookingItems } from "./BookingItems";
import { BookingInvoiceItems } from "./BookingInvoiceItems";
import { useBookingDeliver } from "@/api/useBookingDeliver";
import { useBookingReturn } from "@/api/useBookingReturn";
import { t } from "i18next";
import { ArrowLeft, Clock, History } from "lucide-react";
import { cn } from "@/lib/utils";
import { BookingDetailsNotes } from "./BookingDetailsNotes";
import "./BookingDetailsPage.css";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { BookingDocuments } from "./BookingDocuments";
import { BookingCustomerDetails } from "./BookingCustomerDetails";
import { BookingSchedule } from "./BookingSchedule";
import { ReturnBookingConfirmationModal } from "./ReturnBookingConfirmationModal";
import { BookingDetailsAgent } from "./BookingDetailsAgent";
import { useFacilitySettings } from "@/api/useFacilitySettings";
import { useBookingConfirm } from "@/api/useBookingConfirm";
import { useLocations } from "@/api/useLocations";
import { BookingDetailsLocations } from "./BookingDetailsLocations";
import { BookingStatusButton } from "@/components/specific/BookingStatusButton";
import { BookingPaymentLinkModal } from "./BookingPaymentLinkModal";
import { ButtonLoadable } from "@/components/ui/button-loadable";
import { BookingTraceLogSheet } from "./traceLog/BookingTraceLogSheet";

export function BookingDetailsPage() {
  const { bookingId } = useParams();
  const booking = useBooking(bookingId);

  if (!booking.data) {
    return <Spinner />;
  }

  return <BookingDetailsParamsResolvedPage booking={booking.data} />;
}

export function BookingDetailsParamsResolvedPage({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const deliverBooking = useBookingDeliver(booking.id, booking.items.length);
  const confirmBooking = useBookingConfirm(booking.id);
  const returnBooking = useBookingReturn(booking.id);
  const { settings } = useFacilitySettings(booking.facilityId);
  const { locations } = useLocations(booking.facilityId);
  const isPaymentLinkAllowed = booking.invoice.totalDue >= 2;

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between md:items-center mb-3">
        <div className="text-xl flex items-center">
          <Link to="../bookings">
            <Button variant="ghost" size="sm">
              <ArrowLeft size={20} />
            </Button>
          </Link>
          <span className="mr-3">
            {t("bookings_details_title", "Booking")} <span className="font-bold">#{booking.reference}</span>
          </span>
          <BookingStatusButton booking={booking} />
          <BookingTraceLogSheet booking={booking}>
            <Button variant="outline" className="ml-3 flex gap-1 items-center">
              <History size={16} className="mr-1" />
              <div className="hidden sm:block">{t("booking_traceLog_title", "Booking history")}</div>
            </Button>
          </BookingTraceLogSheet>
        </div>
        <div className={cn("flex py-3 justify-center", booking.items.length ? null : "hidden")}>
          <div className="mr-3">
            {booking.status === "PENDING" ? (
              <ConfirmDialog
                title={t("bookings_details_deliver_confirmation_title", "Deliver booking")}
                description={t(
                  "bookings_details_deliver_confirmation_description",
                  "Did you deliver all items of this booking?"
                )}
                okButtonText={t("common_button_yes", "Yes")}
                onContinue={() => deliverBooking.mutate()}
              >
                <ButtonLoadable isLoading={deliverBooking.isPending}>
                  {t("bookings_details_deliver_button", "Deliver")}
                </ButtonLoadable>
              </ConfirmDialog>
            ) : booking.status === "UNCONFIRMED" ? (
              <ConfirmDialog
                title={t("bookings_details_confirm_confirmation_title", "Confirm booking")}
                description={t(
                  "bookings_details_confirm_confirmation_description",
                  "You are about to confirm this booking. Do you want ot continue?"
                )}
                okButtonText={t("common_button_yes", "Yes")}
                onContinue={() => confirmBooking.mutate()}
              >
                <ButtonLoadable isLoading={confirmBooking.isPending}>
                  {t("bookings_details_confirm_button", "Confirm")}
                </ButtonLoadable>
              </ConfirmDialog>
            ) : (
              <Button variant="secondary" disabled={true}>
                {t("bookings_details_deliver_button", "Deliver")}
              </Button>
            )}
          </div>

          <div>
            {booking.status === "IN_PROGRESS" ? (
              <ReturnBookingConfirmationModal
                hasDeposit={!!booking.invoice.deposit}
                onContinue={() => returnBooking.mutate()}
              >
                <ButtonLoadable isLoading={returnBooking.isPending}>
                  {t("bookings_details_return_button", "Return")}
                </ButtonLoadable>
              </ReturnBookingConfirmationModal>
            ) : (
              <Button variant="secondary" disabled={true}>
                {t("bookings_details_return_button", "Return")}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="grid w-full gap-3 booking-details__grid">
        <div style={{ gridArea: "details" }}>
          <BookingCustomerDetails booking={booking} />
        </div>
        <Card style={{ gridArea: "schedule" }}>
          <CardHeader className="flex flex-row items-center space-y-0">
            <CardTitle>{t("bookings_schedule_title", "Schedule")}</CardTitle>
            <div className="flex items-center ml-3 text-muted-foreground">
              <Clock size={16} className="mr-1" />
              {booking.period?.name}
            </div>
          </CardHeader>
          <CardContent>
            <BookingSchedule booking={booking} />
          </CardContent>
        </Card>
        <Card style={{ gridArea: "documents" }} className="relative flex flex-col">
          <CardHeader>
            <CardTitle>{t("bookings_documents_title", "Documents")}</CardTitle>
          </CardHeader>
          <CardContent className="flex-1">
            <BookingDocuments bookingId={booking.id} hasEmail={Boolean(booking.customer.email)} />
          </CardContent>
        </Card>
        <Card style={{ gridArea: "items" }}>
          <CardHeader>
            <CardTitle>{t("bookings_products_title", "Products")}</CardTitle>
          </CardHeader>
          <CardContent>
            <BookingItems booking={booking} />
          </CardContent>
        </Card>
        <div style={{ gridArea: "payments" }}>
          {settings?.agentsEnabled && (
            <Card className="mb-3">
              <CardHeader>
                <CardTitle>{t("bookings_details_agent", "Agent")}</CardTitle>
              </CardHeader>
              <CardContent>
                <BookingDetailsAgent booking={booking} />
              </CardContent>
            </Card>
          )}
          {locations.length > 0 && (
            <Card className="mb-3">
              <CardHeader>
                <CardTitle>{t("bookings_details_locations", "Locations")}</CardTitle>
              </CardHeader>
              <CardContent>
                <BookingDetailsLocations booking={booking} />
              </CardContent>
            </Card>
          )}
          <Card className="relative">
            <CardHeader>
              <CardTitle>{t("bookings_documents_notes", "Notes")}</CardTitle>
            </CardHeader>
            <CardContent className="pb-0">
              <BookingDetailsNotes booking={booking} />
            </CardContent>
          </Card>
          <Card className="mt-3">
            <CardHeader>
              <div className="flex items-center justify-between">
                <CardTitle>{t("bookings_invoice_title", "Invoice")}</CardTitle>
                {isPaymentLinkAllowed && (
                  <BookingPaymentLinkModal bookingId={booking.id} facilityId={booking.facilityId}>
                    <Button variant="link" className="px-0">
                      {t("bookings_invoice_paymentLink_button", "Get payment link")}
                    </Button>
                  </BookingPaymentLinkModal>
                )}
              </div>
            </CardHeader>
            <CardContent>
              <BookingInvoiceItems booking={booking} />
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
