import { useBookingDocuments } from "@/api/useBookingDocuments";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";
import { BookingSignModal } from "./BookingSignModal";
import { useTranslation } from "react-i18next";
import { ClipboardCheck, ClipboardSignatureIcon, Download, MailIcon, RefreshCcw } from "lucide-react";
import { cn } from "@/lib/utils";
import { useBookingDocumentsRemoveSignatures } from "@/api/useBookingDocumentsRemoveSignatures";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { TooltipSimple } from "@/components/ui/tooltip";
import { useBookingDocumentSend } from "@/api/useBookingDocumentSend";
import { formatDate } from "@/components/specific/DateFormat";
import { useDocumentCreatePoNumber } from "@/api/useDocumentCreatePoNumber";

export function BookingDocuments({ bookingId, hasEmail }: { bookingId: string; hasEmail: boolean }) {
  const { documents, isLoading, reload } = useBookingDocuments(bookingId);
  const hasToSign = documents.some((doc) => !doc.signedAt && doc.isSignable);
  const hasAtLeastOneSignature = documents.some((doc) => doc.signedAt);
  const { t } = useTranslation();
  const removeSignatures = useBookingDocumentsRemoveSignatures(bookingId);
  const sendDocument = useBookingDocumentSend(bookingId);
  const createPoNumber = useDocumentCreatePoNumber(bookingId);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="text-sm h-full">
      {documents.length === 0 && (
        <span className="text-muted-foreground">
          {t("booking_details_documents_noDoucmentsFound", "No documents found for this booking")}
        </span>
      )}
      {hasToSign && (
        <div className="absolute right-0 top-0 p-6 h-6">
          <Button style={{ marginTop: "-7px" }} size="sm" variant="ghost" onClick={() => reload()}>
            <RefreshCcw size={14} />
          </Button>
        </div>
      )}
      <div className="flex flex-col h-full">
        {documents.map((doc) => (
          <div key={doc.id} className="flex items-center space-x-3 pb-1">
            <div className="flex items-center flex-1">{doc.poNumber ? `#${doc.poNumber}` : doc.name}</div>
            <div className="flex justify-end items-center w-24">
              {doc.isSignable && (
                <span className="pr-1.5">
                  <TooltipSimple
                    className="flex"
                    text={
                      doc.signedAt
                        ? t("booking_details_documents_signature_signedOn", "Signed on {{date}}", {
                            date: formatDate(doc.signedAt),
                          })
                        : t("booking_details_documents_signature_notSigned", "Not signed yet")
                    }
                  >
                    <ClipboardSignatureIcon
                      size={16}
                      className={cn(doc.signedAt ? "text-green-600" : "text-destructive")}
                    />
                  </TooltipSimple>
                </span>
              )}
              {doc.canCreatePoNumber &&
                (!doc.poNumber ? (
                  <span>
                    <TooltipSimple
                      className="flex"
                      text={t(
                        "booking_details_documents_poNumber_generate_tooltip",
                        'The document is "Pro forma". Click to generate a number.'
                      )}
                    >
                      <Button
                        variant="ghost"
                        size="xs"
                        onClick={() => createPoNumber.mutate(doc.id)}
                        disabled={!!doc.poNumber}
                      >
                        <ClipboardCheck size={16} className="text-destructive flex-shrink-0" />
                      </Button>
                    </TooltipSimple>
                  </span>
                ) : (
                  <ClipboardCheck size={16} className="text-green-600 mx-1.5 flex-shrink-0" />
                ))}
              {doc.sendToCustomers && (
                <TooltipSimple
                  className="flex"
                  text={
                    !hasEmail
                      ? t(
                          "booking_details_documents_send_missingEmail",
                          "Add the customer email, if you would like them to receive the document"
                        )
                      : doc.isSignable && !doc.signedAt && !doc.poNumber
                        ? t(
                            "booking_details_documents_send_missingSignature",
                            "Cannot be sent because it is not signed yet."
                          )
                        : doc.sentAt
                          ? t("booking_details_documents_send_sentAt", "The document was sent on {{date}}", {
                              date: formatDate(doc.sentAt),
                            })
                          : t("booking_details_documents_send_sendToCustomer", "Send the document to the customer")
                  }
                >
                  <ConfirmDialog
                    title={t("booking_details_documents_send_confirm_title", "Send to the customer")}
                    description={t(
                      "booking_details_documents_send_confirm_description",
                      "Are you sure you want to send this document to the customer?"
                    )}
                    onContinue={() => sendDocument.mutate(doc.id)}
                  >
                    <Button
                      variant="ghost"
                      size="xs"
                      disabled={!hasEmail || (doc.isSignable && !doc.signedAt && !doc.poNumber)}
                      className={cn(
                        !hasEmail ||
                          (doc.isSignable &&
                            !doc.signedAt &&
                            !doc.poNumber &&
                            "cursor-not-allowed text-muted-foreground")
                      )}
                    >
                      <MailIcon
                        size={16}
                        className={cn(!hasEmail && "text-muted-foreground", doc.sentAt && "text-green-600")}
                      />
                    </Button>
                  </ConfirmDialog>
                </TooltipSimple>
              )}
              {doc.url ? (
                <>
                  <a href={doc.url} target="_blank">
                    <Button variant="ghost" size="xs">
                      <Download size={16} />
                    </Button>
                  </a>
                </>
              ) : (
                <>
                  {doc.isSignable && (
                    <Button size="xs" variant="ghost" disabled={true} className="text-muted-foreground">
                      <Download size={16} />
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
        <div className="mt-auto text-right space-x-3">
          {hasAtLeastOneSignature && (
            <ConfirmDialog
              title={t("booking_details_documents_sign_deleteSignatures_title", "Delete signatures")}
              description={t(
                "booking_details_documents_sign_deleteSignatures_description",
                "Are you sure you want to delete all signed documents for this booking? This action is irreversible and signatures will be lost."
              )}
              onContinue={() => removeSignatures.mutate()}
              okButtonText={t("booking_details_documents_sign_deleteSignatures_confirm", "Delete forever")}
              okButtonVariant="destructive"
            >
              <Button className="mt-3" variant="outline" size="sm">
                {t("booking_details_documents_sign_deleteSignatures_button", "Clear signatures")}
              </Button>
            </ConfirmDialog>
          )}
          {hasToSign && (
            <BookingSignModal documents={documents} bookingId={bookingId}>
              <Button className="mt-3" size="sm" variant="secondary">
                {t("booking_details_documents_sign_button", "Sign All")}
              </Button>
            </BookingSignModal>
          )}
        </div>
      </div>
    </div>
  );
}
