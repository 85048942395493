import { FunctionComponent, SVGProps } from "react";
import { ReactComponent as en } from "flag-icons/flags/4x3/gb.svg";
import { ReactComponent as es } from "flag-icons/flags/4x3/es.svg";

export function LanguageFlag(props: { lang: string; size?: number | string }) {
  const flags: Record<string, FunctionComponent<SVGProps<void>>> = {
    en,
    es,
  };
  const Flag = flags[props.lang] || null;

  return <Flag style={{ width: `${props.size || 16}px` }} />;
}
