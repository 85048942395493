import { MouseEventHandler, PropsWithChildren } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import { cn } from "@/lib/utils";

type UserCardProps = PropsWithChildren<{
  user: {
    name: string;
    email: string | null;
  };
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
}>;

export function UserCard({ user, onClick, className, children }: UserCardProps) {
  const fallback = user.name
    .split(" ")
    .slice(0, 2)
    .map((names) => names[0])
    .join("");

  return (
    <div
      className={cn("grid gap-3 w-full", className)}
      style={{ gridTemplateColumns: "40px 1fr auto" }}
      onClick={onClick}
    >
      <Avatar>
        <AvatarImage src="/avatars/01.png" />
        <AvatarFallback>{fallback}</AvatarFallback>
      </Avatar>
      <div className="min-w-0 flex flex-col justify-center">
        <p className="text-sm font-medium leading-none">{user.name}</p>
        {user.email && <p className="text-sm text-muted-foreground overflow-ellipsis overflow-hidden">{user.email}</p>}
      </div>
      {children}
    </div>
  );
}
