import { useMe } from "@/api/useMe";
import { useMeFacilities } from "@/api/useMeFacilities";
import { useUserAddFacility } from "@/api/useUserAddFacility";
import { useUserFacilities } from "@/api/useUserFacilities";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { SwitchAutosave } from "@/components/ui/switch-autosave";
import { ApiObjects } from "@pulso/api-client";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import keyBy from "lodash/keyBy";
import { useUserRemoveFacility } from "@/api/useUserRemoveFacility";

type UserFormDialogProps = PropsWithChildren<{ user: ApiObjects["UserDto"] }>;

export function UserFacilitiesDialog(props: UserFormDialogProps) {
  const { t } = useTranslation();
  const { facilities: userFacilities } = useUserFacilities(props.user.id);
  const userFacilitiesMap = keyBy(userFacilities, (f) => f.id);
  const add = useUserAddFacility(props.user.id);
  const remove = useUserRemoveFacility(props.user.id);
  const { facilities } = useMeFacilities();

  return (
    <Dialog>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("User facilities")}</DialogTitle>
          <DialogDescription>
            {t("Add or remove {{user}} from facilities", { user: props.user.name })}
          </DialogDescription>
        </DialogHeader>
        <div>
          {facilities.map((facility) => (
            <div key={facility.id} className="flex items-center gap-2 py-2">
              <SwitchAutosave
                notifyRight
                checked={!!userFacilitiesMap[facility.id]}
                onAttempt={() => {
                  return userFacilitiesMap[facility.id]
                    ? remove.mutateAsync(facility.id)
                    : add.mutateAsync(facility.id);
                }}
              />
              <span>{facility.name}</span>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
