import { SlidersHorizontal } from "lucide-react";
import { t } from "i18next";

import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { useBookingsDashboardSettings } from "./useBookingsDashboardSettings";
import { SelectNative } from "@pulso/components/lib/SelectNative";

export function BookingsDashboardSettings() {
  const { settings, setPeriodLabel } = useBookingsDashboardSettings();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <SlidersHorizontal size={16} />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <div className="grid grid-cols-2 items-center">
          <div>{t("bookings_dashboard_settings_period", "Period label")}</div>
          <div>
            <SelectNative
              value={settings.periodLabel}
              onChange={(v) => setPeriodLabel(v.target.value === "DURATION" ? "DURATION" : "LABEL")}
            >
              <option value="DURATION">{t("bookings_dashboard_settings_period_option_duration", "Duration")}</option>
              <option value="LABEL">{t("bookings_dashboard_settings_period_option_label", "Label")}</option>
            </SelectNative>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
