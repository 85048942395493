import { useCustomerFields } from "@/api/useCustomerFields";
import { Spinner } from "@/components/ui/spinner";
import { useParams } from "react-router-dom";
import { CustomerFieldCreateDialog } from "./CustomerFieldCreateDialog";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useTranslation } from "react-i18next";
import { ApiObjects } from "@pulso/api-client";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { Button } from "@/components/ui/button";
import { ArrowDown, ArrowUp, Check, EditIcon, Trash2Icon } from "lucide-react";
import { useCustomerFieldsDelete } from "@/api/useCustomerFieldsDelete";
import { CustomerFieldUpdateDialog } from "./CustomerFieldUpdateDialog";
import { useCustomerFieldsUpdate } from "@/api/useCustomerFieldsUpdate";
import { cn } from "@/lib/utils";
import { Card, CardContent } from "@/components/ui/card";

export function CustomerFieldsPage() {
  const { facilityId } = useParams();
  const { fields, isLoading } = useCustomerFields(facilityId);
  const { t } = useTranslation();
  const deleteField = useCustomerFieldsDelete();
  const updateField = useCustomerFieldsUpdate();

  const FIELD_TYPE_LABELS: Record<ApiObjects["CustomerFieldDto"]["type"], string> = {
    TEXT: t("products_fields_form_option_text"),
    OPTION: t("products_fields_form_option_options"),
    FILE: t("customerFields_field_type_file", "File"),
    MULTILINE: t("customerFields_field_type_file", "Multi-line text"),
  };

  if (isLoading || !facilityId) {
    return <Spinner />;
  }

  return (
    <Card>
      <CardContent className="pt-6 space-y-6">
        <CustomerFieldCreateDialog facilityId={facilityId} />
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("customerField_form_label_name", "Name")}</TableHead>
              <TableHead>{t("customerField_form_label_type", "Type")}</TableHead>
              <TableHead>{t("customerField_form_label_mandatory", "Mandatory")}</TableHead>
              <TableCell>{t("customerField_form_label_showInFastTrack", "Fast Track")}</TableCell>
              <TableCell>{t("customerField_form_label_showInStore", "Online Store")}</TableCell>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>{t("customer_form_label_firstname")}</TableCell>
              <TableCell>{FIELD_TYPE_LABELS.TEXT}</TableCell>
              <TableCell>{t("common_mandatory", "Mandatory")}</TableCell>
              <TableCell>
                <Check size={14} />
              </TableCell>
              <TableCell>
                <Check size={14} />
              </TableCell>
              <TableCell>
                <Button variant="ghost" className="invisible">
                  <EditIcon strokeWidth={1.2} />
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("customer_form_label_lastname")}</TableCell>
              <TableCell>{FIELD_TYPE_LABELS.TEXT}</TableCell>
              <TableCell>{t("common_mandatory", "Mandatory")}</TableCell>
              <TableCell>
                <Check size={14} />
              </TableCell>
              <TableCell>
                <Check size={14} />
              </TableCell>
              <TableCell>
                <Button variant="ghost" className="invisible">
                  <EditIcon strokeWidth={1.2} />
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("customer_form_label_email")}</TableCell>
              <TableCell>{FIELD_TYPE_LABELS.TEXT}</TableCell>
              <TableCell>{t("common_optional", "Optional")}</TableCell>
              <TableCell>
                <Check size={14} />
              </TableCell>
              <TableCell>
                <Check size={14} />
              </TableCell>
              <TableCell>
                <Button variant="ghost" className="invisible">
                  <EditIcon strokeWidth={1.2} />
                </Button>
              </TableCell>
            </TableRow>
            {fields.map((field) => (
              <TableRow key={field.id}>
                <TableCell>{field.name}</TableCell>
                <TableCell>{FIELD_TYPE_LABELS[field.type]}</TableCell>
                <TableCell>
                  {field.mandatory ? t("common_mandatory", "Mandatory") : t("common_optional", "Optional")}
                </TableCell>
                <TableCell>{field.showInFastTrack && <Check size={14} />}</TableCell>
                <TableCell>{field.showInStore && <Check size={14} />}</TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    onClick={() => updateField.mutate({ ...field, position: field.position - 1 })}
                    className={cn(field.position <= 1 && "invisible")}
                  >
                    <ArrowUp strokeWidth={1.2} />
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => updateField.mutate({ ...field, position: field.position + 1 })}
                    className={cn(field.position >= fields.length && "invisible")}
                  >
                    <ArrowDown strokeWidth={1.2} />
                  </Button>

                  <CustomerFieldUpdateDialog field={field}>
                    <Button variant="ghost">
                      <EditIcon strokeWidth={1.2} />
                    </Button>
                  </CustomerFieldUpdateDialog>
                  <ConfirmDialog
                    title={t("customerFields_delete_title", "Delete {{field}}", { field: field.name })}
                    description={t(
                      "customerFields_delete_description",
                      "Are you sure you want to delete this customer field?"
                    )}
                    onContinue={() => deleteField.mutate(field.id)}
                  >
                    <Button variant="ghost">
                      <Trash2Icon strokeWidth={1.2} />
                    </Button>
                  </ConfirmDialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
