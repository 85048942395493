import { useBookingItemCreate } from "@/api/useBookingItemCreate";
import { usePeriodsWithPrice } from "@/api/usePeriodsWithPrice";
import { useProduct } from "@/api/useProduct";
import { useProducts } from "@/api/useProducts";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Spinner } from "@/components/ui/spinner";
import { ApiObjects } from "@pulso/api-client";
import { t } from "i18next";
import { PropsWithChildren, useState } from "react";
import { Link } from "react-router-dom";

type NewBookingItemModalProps = PropsWithChildren<{ booking: ApiObjects["BookingDto"] }>;

export function NewBookingItemModal({ booking, children }: NewBookingItemModalProps) {
  const products = useProducts();
  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState<string | null>(null);
  const [periodId, setPeriodId] = useState<string | null>(null);
  const [fieldValues, setFieldValues] = useState<Record<string, string>>({});
  const product = useProduct(productId);
  const priceableFields = product.data?.fields.filter((field) => field.priceable) || [];
  const needFieldData = !productId || priceableFields.some((f) => !fieldValues[f.id]);
  const needMoreData = !periodId || needFieldData;
  const periodsWithPrice = usePeriodsWithPrice(
    productId,
    Object.keys(fieldValues).map((key) => ({ value: fieldValues[key] }))
  );
  const enabledPeriodsWithPrice = periodsWithPrice.filter((p) => p.enabled);

  const addBookingItem = useBookingItemCreate(booking.id);

  if (!products.data) {
    return <Spinner />;
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="lg:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {productId
              ? t("bookings_items_create_fields_title", "Choose options")
              : t("bookings_items_create_product_title", "Select a product")}
          </DialogTitle>
          <DialogDescription>
            {productId
              ? t("bookings_items_create_fields_subtitle", "Specify the options the customer requires")
              : t("bookings_items_create_product_subtitle", "Which product would the customer want")}
          </DialogDescription>
        </DialogHeader>
        <div>
          {!productId ? (
            <ProductsList products={products.data} onSelect={setProductId} />
          ) : enabledPeriodsWithPrice.length ? (
            <>
              <FieldsForm
                fields={priceableFields}
                values={fieldValues}
                onChange={(id, value) => setFieldValues({ ...fieldValues, [id]: value })}
              />
              <div className="max-h-72 overflow-y-auto">
                <FieldInput
                  field={{
                    name: t("bookings_items_create_form_label_period", "Period"),
                    options: enabledPeriodsWithPrice.map((period) => ({
                      id: period.id,
                      value: period.name,
                      disabled: typeof period.price !== "number",
                    })),
                  }}
                  value={periodId}
                  onChange={(value) => setPeriodId(value)}
                ></FieldInput>
              </div>
            </>
          ) : (
            <div className="text-sm text-muted-foreground pb-6">
              {t(
                "bookings_items_create_product_noPeriodsEnabled",
                "No prices are defined for this product yet. You need to set the prices before you can add it to bookings."
              )}
            </div>
          )}
        </div>
        <DialogFooter>
          {productId &&
            (enabledPeriodsWithPrice.length ? (
              <Button onClick={onSave} disabled={needMoreData}>
                {t("common_button_save")}
              </Button>
            ) : (
              <Link to={`../products/${productId}`}>
                <Button variant="outline">
                  {t("bookings_items_create_product_noPeriodsEnabled_button", "Set prices")}
                </Button>
              </Link>
            ))}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  function onSave() {
    if (needMoreData) {
      return;
    }

    addBookingItem.mutate(
      {
        periodId,
        productId,
        fields: Object.keys(fieldValues).map((fieldId) => ({ fieldId, value: fieldValues[fieldId] })),
      },
      { onSuccess: () => setOpen(false) }
    );
  }

  function onOpenChange(isOpen: boolean) {
    setOpen(isOpen);
    setProductId(null);
    setPeriodId(null);
    setFieldValues({});
  }
}

function ProductsList({
  products,
  onSelect,
}: {
  products: ApiObjects["ProductDto"][];
  onSelect: (productId: string) => void;
}) {
  return (
    <div className="grid grid-cols-2 gap-4">
      {products.map((product) => (
        <Card
          className="p-6 cursor-pointer flex items-center justify-center"
          key={product.id}
          onClick={() => onSelect(product.id)}
        >
          {product.name}
        </Card>
      ))}
    </div>
  );
}

function FieldsForm({
  fields,
  values,
  onChange,
}: {
  fields: ApiObjects["ProductWithExtraDto"]["fields"];
  values: Record<string, string | null>;
  onChange: (id: string, value: string) => void;
}) {
  return (
    <div>
      {fields.map((field) => (
        <div key={field.id} className="mb-3">
          <FieldInput field={field} value={values[field.id]} onChange={(value) => onChange(field.id, value)} />
        </div>
      ))}
    </div>
  );
}

function FieldInput({
  field,
  value,
  onChange,
}: {
  field: { name: string; options: Array<{ id: string; value: string; disabled?: boolean }> };
  value: string | null;
  onChange: (value: string) => void;
}) {
  return (
    <div>
      <div className="mb-3">{field.name}</div>
      <div className="flex flex-wrap">
        {field.options.map((opt) => (
          <Button
            key={opt.id}
            className="mr-3 mb-3"
            variant={value === opt.id ? "default" : "outline"}
            onClick={() => onChange(opt.id)}
            disabled={opt.disabled}
          >
            {opt.value}
          </Button>
        ))}
      </div>
    </div>
  );
}
