import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { ApiObjects } from "@pulso/api-client";
import { formatDateTnLocalTimezone } from "@pulso/utils";
import { useQuery } from "@tanstack/react-query";

const DEFAULT_STATUS: ApiObjects["BookingStatsGetResponse"] = {
  UNCONFIRMED: 0,
  PENDING: 0,
  IN_PROGRESS: 0,
  COMPLETED: 0,
  CANCELLED: 0,
  ON_HOLD: 0,
};

export function useBookingStats(filter: { searchTerm: string | null; date: Date; dateTo?: Date }) {
  const auth = useAuth();

  const query = useQuery({
    queryKey: ["bookings", "stats", auth.facility?.id, filter],
    queryFn: async (context) => {
      if (!auth.facility?.id) {
        return DEFAULT_STATUS;
      }

      return await api.getBookingStats({
        facilityId: auth.facility.id,
        from: formatDateTnLocalTimezone(filter.date),
        to: "",
      });
    },
    staleTime: 60000,
  });

  return query.data || DEFAULT_STATUS;
}
