import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { useInfiniteScrollQuery } from "@/lib/useInfiniteScrollQuery";
import { ApiObjects } from "@pulso/api-client";
import { formatDateTnLocalTimezone } from "@pulso/utils";

export function useBookings(filter: {
  searchTerm: string | null;
  date: Date;
  dateTo?: Date;
  pageSize: number;
  statuses: Record<ApiObjects["BookingDto"]["status"], boolean>;
}) {
  const auth = useAuth();

  const query = useInfiniteScrollQuery({
    queryKey: ["bookings", auth.facility?.id, filter],
    queryFn: async (context) => {
      if (!auth.facility?.id) {
        return {
          items: [] as ApiObjects["BookingDto"][],
          page: context.pageParam,
          pageSize: filter.pageSize,
          total: 0,
        };
      }

      const status = Object.entries(filter.statuses)
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(",");

      const bookings = await api.getBookings({
        facilityId: auth.facility.id,
        q: filter.searchTerm || "",
        from: formatDateTnLocalTimezone(filter.date),
        to: filter.dateTo ? formatDateTnLocalTimezone(filter.dateTo) : "",
        ...(status ? { status } : {}),
        page: context.pageParam,
        pageSize: filter.pageSize,
      });

      return {
        items: bookings.bookings,
        page: bookings.page,
        total: bookings.total,
        pageSize: bookings.pageSize,
      };
    },
    staleTime: 60000,
  });

  return query;
}
