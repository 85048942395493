import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export function useCustomers(q: string) {
  const auth = useAuth();

  const query = useQuery({
    queryKey: ["customers", auth.facility?.id, q || ""],
    queryFn: async () => {
      if (!auth.facility?.id) {
        return [];
      }

      if (q.length < 3) {
        return [];
      }

      return await api.getCustomers({ facilityId: auth.facility.id, q });
    },
    placeholderData: keepPreviousData,
  });

  return {
    customers: query.data || [],
    isLoading: query.isLoading,
  };
}
