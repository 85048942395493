import { cn } from "@/lib/utils";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationLink,
  PaginationNext,
} from "../ui/pagination";

export type AutoPaginationProps = {
  page: number;
  pageSize: number;
  total: number;
  onChange: (page: number) => void;
};

export function AutoPagination({ page: currentPage, total, pageSize, onChange }: AutoPaginationProps) {
  const pages = [...new Array(Math.ceil(total / pageSize))].map((p, i) => i + 1);

  if (pages.length === 1) {
    return null;
  }

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem className={cn(currentPage <= 1 ? "invisible" : "")}>
          <PaginationPrevious className="cursor-pointer" onClick={() => onChange(currentPage - 1)} />
        </PaginationItem>
        {pages.map((page) => (
          <PaginationItem key={page}>
            <PaginationLink className="cursor-pointer" onClick={() => onChange(page)} isActive={page === currentPage}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem className={cn(currentPage >= pages.length ? "invisible" : "")}>
          <PaginationNext className="cursor-pointer" onClick={() => onChange(currentPage + 1)} />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}
