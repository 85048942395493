import * as React from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";
import { LanguageFlag } from "./LanguageFlag";

export interface SelectProps extends React.ComponentPropsWithoutRef<typeof Select> {}

const LanguagePicker = React.forwardRef<React.ElementRef<typeof Select>, SelectProps>(({ ...props }, ref) => {
  const langs = [
    { value: "en", label: "English" },
    { value: "es", label: "Español" },
  ];

  return (
    <Select {...props} ref={ref}>
      <SelectTrigger className="bg-white">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="bg-white">
        {langs.map((l) => (
          <SelectItem key={l.value} value={l.value} className="cursor-pointer focus:bg-secondary/75">
            <div className="flex items-center gap-2">
              <LanguageFlag lang={l.value} size="16" />
              <span>{l.label}</span>
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
});
LanguagePicker.displayName = "LanguagePicker";

export { LanguagePicker };
