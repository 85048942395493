import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "@/components/ui/spinner";
import { t } from "i18next";
import { useEffect } from "react";
import { cn } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";

export function SettingsPage() {
  const { facilityId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const active = location.pathname.match(/settings\/([a-z-]+)\/?/);
  const currentTab = (active && active[1]) || "";

  const items = [
    {
      title: t("settings_facility_title", "Facility"),
      description: t("settings_facility_description", "Update the basic settings of your facility"),
      href: "facility",
    },
    {
      title: t("settings_users_title", "Users"),
      description: t("settings_users_description", "Manage your staff"),
      href: "users",
    },
    {
      title: t("settings_rentalPeriods_title", "Rental periods"),
      description: t("settings_rentalPeriods_description", "Define the periods and seasons used for all bookings"),
      href: "rental-periods",
    },
    {
      title: t("settings_customerFields_title", "Customer fields"),
      description: t(
        "settings_customerFields_description",
        "Add extra information you would like to know about your customers"
      ),
      href: "customer-fields",
    },
    {
      title: t("settings_openingHours_title", "Opening hours"),
      description: t("settings_openingHours_description", "Define the opening hours for your facility"),
      href: "opening-hours",
    },
    {
      title: t("settings_extraCharges_title", "Extra charges & Discounts"),
      description: t("settings_extraCharges_description", "Add commonly used extra charges and discounts"),
      href: "extra-charges",
    },
    {
      title: t("documents_title", "Documents"),
      description: t("settings_documents_description", "Add documents that can be attached to a booking"),
      href: "documents",
    },
    {
      title: t("settings_fastTrack_title", "Fast Track"),
      description: t(
        "settings_fastTrack_description",
        "Setup Fast Track, an app where your clients can create bookings from a shareable link"
      ),
      href: "fast-track",
    },
    {
      title: t("settings_agents_title", "Agents"),
      description: t("settings_agents_description", "Define commission scheme for agents that help your business"),
      href: "agents",
    },
    {
      title: t("settings_store_title", "Online store"),
      description: t("settings_store_description", "Setup your online store and integrate it with your website"),
      href: "store",
    },
    // { title: t("settings_locations_title", "Locations"), href: "locations" },
  ];

  const activeItem = items.find((item) => item.href === currentTab);

  useEffect(() => {
    if (!currentTab) {
      navigate("facility");
    }
  }, [currentTab, navigate]);

  if (!facilityId) {
    return <Spinner />;
  }

  return (
    <div className="flex h-full">
      <div className="w-40 min-w-32 h-full py-5 px-2 flex flex-col gap-1 text-sm bg-secondary-dark overflow-y-auto">
        {items.map((item) => (
          <Link key={item.href} to={item.href}>
            <div
              className={cn(
                "rounded-md py-2 px-4 text-secondary-foreground",
                item === activeItem && "bg-secondary-darker",
                item !== activeItem && "hover:bg-secondary-darker"
              )}
            >
              {item.title}
            </div>
          </Link>
        ))}
      </div>
      <div className="flex-1 h-full overflow-y-auto">
        <div className="h-full">
          <div className="p-6">
            <div className="space-y-0.5">
              <h2 className="text-2xl font-bold tracking-tight">{activeItem?.title}</h2>
              <p className="text-muted-foreground">{activeItem?.description}</p>
            </div>
            <Separator className="my-6" />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
