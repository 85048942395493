import { z } from "zod";
import { PulsoFormProvider, createForm } from "../../../components/specific/Form";
import { useProducts } from "@/api/useProducts";
import { t } from "i18next";
import { useDocuments } from "@/api/useDocuments";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, CardContent } from "@/components/ui/card";
import { Spinner } from "@/components/ui/spinner";
import { ApiObjects } from "@pulso/api-client";
import { useDocumentCreate } from "@/api/useDocumentCreate";
import { useDocumentUpdate } from "@/api/useDocumentUpdate";
import { ArrowLeft } from "lucide-react";
import { memo } from "react";
import { useMe } from "@/api/useMe";

export const DocumentDetailsPage = memo(
  DocumentDetailsPageRaw,
  (prev, next) => (!prev.document.id && !next.document.id) || prev.document.id === next.document.id
);

export function DocumentDetailsPageRaw({
  document,
  products,
  isLoading,
  onSubmit,
}: {
  document: ApiObjects["DocumentDto"];
  products: ApiObjects["ProductDto"][];
  isLoading: boolean;
  onSubmit: (values: ApiObjects["CreateDocumentBody"]) => void;
}) {
  const { user } = useMe();
  const formSchema = z.object({
    name: z.string().min(2).max(50),
    content: z.string().min(2),
    marginX: z.string(),
    marginY: z.string(),
    pageFormat: z.enum(["A4", "A5", "A6", "Letter", "Continuous"]),
    pageWidth: z.number().optional(),
    poNumberFormat: z.string().optional(),
    products: z.array(z.string()),
    sendToCustomers: z.boolean(),
    mandatorySignature: z.boolean(),
  });

  const form = createForm(
    {
      name: {
        label: t("documents_form_label_name", "Name"),
        type: "string",
      },
      content: {
        label: t("documents_form_label_document", "Document"),
        type: "richtext",
        language: user?.language || "es",
      },
      marginX: {
        label: t("documents_form_label_horizontalMargin", "Page Margin - Horizontal"),
        type: "select",
        options: [
          { value: "0in", label: t("documents_margins_option_noMargin", "No margin") },
          { value: "0.25in", label: t("documents_margins_option_tiny", "Tiny") },
          { value: "0.5in", label: t("documents_margins_option_narrow", "Narrow") },
          { value: "0.75in", label: t("documents_margins_option_moderate", "Moderate") },
          { value: "1in", label: t("documents_margins_option_normal", "Normal") },
          { value: "2in", label: t("documents_margins_option_wide", "Wide") },
        ],
        className: "w-64",
      },
      marginY: {
        label: t("documents_form_label_verticalMargin", "Page Margin - Vertical"),
        type: "select",
        options: [
          { value: "0in", label: t("documents_margins_option_noMargin", "No margin") },
          { value: "0.25in", label: t("documents_margins_option_tiny", "Tiny") },
          { value: "0.5in", label: t("documents_margins_option_narrow", "Narrow") },
          { value: "0.75in", label: t("documents_margins_option_moderate", "Moderate") },
          { value: "1in", label: t("documents_margins_option_normal", "Normal") },
          { value: "2in", label: t("documents_margins_option_wide", "Wide") },
        ],
        className: "w-64",
      },
      pageFormat: {
        label: t("documents_form_label_pageFormat", "Page Format"),
        type: "select",
        options: [
          { value: "A4", label: "A4" },
          { value: "A5", label: "A5" },
          { value: "A6", label: "A6" },
          { value: "Letter", label: "Letter" },
          { value: "Continuous", label: t("documents_pageFormat_option_continuous", "Continuous") },
        ],
        className: "w-64",
      },
      pageWidth: {
        label: t("documents_form_label_pageWidth", "Page Width (millimetres)"),
        type: "number",
        hideIf: (values) => values.pageFormat !== "Continuous",
        className: "w-64",
      },
      poNumberFormat: {
        label: t("documents_form_label_poNumberFormat", "P.O. number prefix"),
        type: "string",
        className: "w-64",
        disableIf: () => !!document.poNumberFormat,
      },
      products: {
        label: t("documents_form_label_products", "Linked products"),
        type: "multi",
        options: [],
      },
      sendToCustomers: {
        placeholder: t("documents_form_label_sendToCustomers", "Attach to the delivery email"),
        label: t("documents_form_section_sendToCustomers", "Send to customers"),
        type: "switch",
      },
      mandatorySignature: {
        label: t("documents_form_label_mandatorySignature", "Require signature"),
        placeholder: t(
          "documents_form_section_mandatorySignature",
          "A signature is mandatory in order to deliver a booking"
        ),
        type: "switch",
        hideIf: (values) => !values.content.includes('data-variable="Signature"'),
      },
    },
    formSchema
  );

  return (
    <div>
      <Card className="pt-6">
        <CardContent>
          <Link to={"../documents"}>
            <div className="mb-6 text-sm flex items-center space-x-1">
              <ArrowLeft size={16} />
              <span>{t("documents_title")}</span>
            </div>
          </Link>

          <PulsoFormProvider
            {...form}
            isLoading={isLoading}
            onSubmit={onSubmit}
            initialValues={{
              name: document?.name || "",
              content: document?.content || "",
              products: document?.products || [],
              sendToCustomers: document?.sendToCustomers || false,
              mandatorySignature: document ? document.mandatorySignature : true,
              marginX: document?.marginX || "0.75in",
              marginY: document?.marginY || "1in",
              pageFormat: document?.pageFormat || "A4",
              pageWidth: document?.pageWidth || 100,
              poNumberFormat: document?.poNumberFormat || "",
            }}
            extra={{ products: { options: products.map((p) => ({ label: p.name, value: p.id })) } }}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export function DocumentUpdatePage() {
  const { documentId } = useParams();
  const { documents, isLoading } = useDocuments();
  const document = documents.find((doc) => doc.id === documentId);
  const products = useProducts();
  const update = useDocumentUpdate(documentId || "");

  if (isLoading || !document) {
    return <Spinner />;
  }

  return (
    <>
      <DocumentDetailsPage
        document={document}
        products={products.data || []}
        isLoading={update.isPending}
        onSubmit={(values) => update.mutate(values)}
      />
    </>
  );
}

export function DocumentCreatePage() {
  const document: ApiObjects["DocumentDto"] = {
    id: "",
    name: "",
    content: "",
    products: [],
    marginX: "0.75in",
    marginY: "1in",
    pageFormat: "A4",
    pageWidth: 100,
    sendToCustomers: false,
    mandatorySignature: true,
    poNumberFormat: "",
  };
  const { facilityId } = useParams();
  const create = useDocumentCreate(facilityId || "");
  const navigate = useNavigate();
  const products = useProducts();

  return (
    <>
      <DocumentDetailsPage
        document={document}
        products={products.data || []}
        isLoading={create.isPending}
        onSubmit={(values) => create.mutate(values, { onSuccess })}
      />
    </>
  );

  function onSuccess(doc: ApiObjects["DocumentDto"]) {
    navigate("../documents/" + doc.id);
  }
}
