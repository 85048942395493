import { useFixedTimeSlots } from "@/pages/settings/rental-periods/useTimeSlots";
import { SelectNative } from "@pulso/components/lib/SelectNative";
import { ForwardedRef, forwardRef } from "react";

type TimePickerProps = {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
};

export const TimePicker = forwardRef(
  ({ min, max, ...props }: TimePickerProps, ref: ForwardedRef<HTMLSelectElement>) => {
    const slots = useFixedTimeSlots(5);

    return (
      <SelectNative
        ref={ref}
        className="w-24"
        defaultValue={props.value}
        onChange={(e) => props.onChange(parseInt(e.target.value))}
        disabled={props.disabled}
      >
        {slots
          .filter((time) => (!min || time.valueNum > min) && (!max || time.valueNum < max))
          .map((time) => (
            <option key={time.value} value={time.value}>
              {time.label}
            </option>
          ))}
      </SelectNative>
    );
  }
);
