import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { useAuth } from "@/lib/useAuth";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { withFacilityIdParam } from "@/lib/withParams";
import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { useFacilityUpdate } from "@/api/useFacilityUpdate";
import { BookingStatusesForm } from "./BookingStatusesForm";

export const FacilitySettingsPage = withFacilityIdParam(({ facilityId }) => {
  const { facility, reloadAuth } = useAuth();
  const update = useFacilityUpdate(facilityId);
  const { t } = useTranslation();

  const formSchema = z.object({
    organizationName: z.string(),
    name: z.string(),
    timezone: z.string(),
    bookingRefScheme: z.enum(["HASH", "NUMBER"]),
    defaultLanguage: z.string(),
    currency: z.string(),
  });

  const form = createForm(
    {
      organizationName: {
        label: t("settings_facility_form_organizationName", "Organization name"),
        type: "string",
        className: "w-64",
      },
      name: {
        label: t("settings_facility_form_name", "Facility name"),
        type: "string",
        className: "w-64",
      },
      timezone: {
        label: t("settings_facility_form_timezone", "Timezone"),
        type: "selectNative",
        options: Intl.supportedValuesOf("timeZone").map((tz) => ({ label: tz, value: tz })),
        className: "w-64",
      },
      bookingRefScheme: {
        label: t("settings_facility_form_bookingFormat", "Booking reference format"),
        type: "radio",
        options: [
          {
            label: t(
              "settings_facility_form_bookingFormat_hash",
              "6-character code consisiting of captial letters and numbers (e.g. J7HI32, NGT543, RE681W, ...)"
            ),
            value: "HASH",
          },
          {
            label: t(
              "settings_facility_form_bookingFormat_number",
              "Number sequence (e.g. 000001, 000002, 000003, ...)"
            ),
            value: "NUMBER",
          },
        ],
      },
      defaultLanguage: {
        label: t("settings_facility_form_defaultLanguage", "Default language for customers"),
        type: "language",
        className: "w-64",
      },
      currency: {
        label: t("settings_facility_form_currency", "Currency"),
        type: "select",
        options: [
          { label: "Euro", value: "EUR" },
          { label: "Dirham (United Arab Emirates)", value: "AED" },
        ],
        className: "w-64",
      },
    },
    formSchema
  );

  if (!facility) {
    return <CentralSpinner />;
  }

  return (
    <div className="grid gap-6">
      <Card>
        <CardHeader>
          <CardTitle>{t("settings_facility_form_title", "Basic settings")}</CardTitle>
          <CardDescription>
            {t("settings_facility_form_description", "Configure the basic settings for this facility")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <PulsoFormProvider
            {...form}
            isLoading={update.isPending}
            initialValues={{
              ...facility,
              organizationName: facility.organization.name,
              defaultLanguage: facility.defaultLanguage,
              currency: facility.currency,
            }}
            onSubmit={(values) => update.mutate(values, { onSuccess: reloadAuth })}
          />
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>{t("settings_bookingStatuses_form_title", "Booking statuses")}</CardTitle>
          <CardDescription>
            {t(
              "settings_bookingStatuses_form_description",
              "Enable or disable the booking statuses you would like to use in your day-to-day operations."
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <BookingStatusesForm facilityId={facilityId} />
        </CardContent>
      </Card>
    </div>
  );
});
