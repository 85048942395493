import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const i18nInstance = i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend);

i18nInstance.init({
  lng: "es", // if you're using a language detector, do not define the lng option
  fallbackLng: "es",
  supportedLngs: ["en", "es"],
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  backend: {
    loadPath: "/i18n/{{lng}}.json",
    // parse(data: string, languages?: string | string[], namespaces?: string | string[]) {
    //   const translations = JSON.parse(data);
    //   return Object.keys(translations).reduce(
    //     (obj, key, i) => ({
    //       ...obj,
    //       [key]: `--- ${translations[key].substring(0, 0)}---`,
    //     }),
    //     {}
    //   );
    // },
  },
});

export default i18nInstance;
