import { Button } from "@/components/ui/button";
import { NewBookingModal } from "./NewBookingModal";
import { DatePicker } from "@/components/ui/date-picker";
import { BookingsFilter, useBookingsFilter } from "./useBookingsFilter";
import { BookingsInputSearch } from "./BookingsInputSearch";
import { ScanIdModal } from "./ScanIdModal";
import { useState } from "react";
import { useAuth } from "@/lib/useAuth";
import { BookingsMultiDayList } from "./BookingsMultiDayList";
import { BookingsDashboardSettings } from "./BookingsDashboardSettings";
import { useBookings } from "@/api/useBookings";
import { formatDate } from "@/components/specific/DateFormat";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { ChevronDown, Ellipsis, Plus, X } from "lucide-react";
import { cn } from "@/lib/utils";
import { useBookingStats } from "@/api/useBookingStats";
import { Entries, isZonedToday, zonedNow } from "@pulso/utils";
import { startOfDay } from "date-fns";
import { BOOKING_STATUS_CLASS_NAME, getBookingStatusLabels } from "@/components/specific/BookingStatus";
import { useTranslation } from "react-i18next";
import { ApiObjects } from "@pulso/api-client";
import { TooltipSimple } from "@/components/ui/tooltip";

export function BookingsPage() {
  const { t } = useTranslation();
  const filter = useBookingsFilter();
  const bookings = useBookings({ ...filter, dateTo: undefined });
  const { facilityId, facility } = useAuth();

  const [scanned, setScanned] = useState<{
    firstname: string;
    lastname: string;
    fields?: Record<string, string>;
  } | null>(null);
  const [hideFilters, setHideFilters] = useState(true);

  if (!facilityId || !facility) {
    return null;
  }

  return (
    <div className="flex flex-col h-full px-3 pt-3 sm:px-6 sm:pt-6">
      <div className={cn("pb-6 overflow-hidden sm:h-auto flex-shrink-0", hideFilters && "h-11")}>
        <div className="flex flex-col space-y-3 items-start sm:flex-row sm:justify-center sm:space-y-0">
          <div className={cn("flex-1 flex w-full sm:block")}>
            <BookingsInputSearch />
            <Button variant="ghost" className="sm:hidden" onClick={() => setHideFilters(!hideFilters)}>
              <ChevronDown size={16} className={cn("transition-transform", !hideFilters && "rotate-180")} />
            </Button>
            <div className="ml-auto sm:hidden">
              <NewBookingModal initialData={scanned} language={facility.defaultLanguage}>
                <Button>
                  <Plus size={16} />
                </Button>
              </NewBookingModal>
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center mr-3">
            <div className="mr-3 sm:ml-3 w-46">
              <DatePicker
                className="w-full"
                mode="single"
                selected={filter.date}
                onSelect={(_, date) => filter.setDate(date)}
                hideClear
              />
            </div>
            {facility && !isZonedToday(filter.date, facility.timezone) ? (
              <div>
                <Button variant="secondary" onClick={() => filter.setDate(startOfDay(zonedNow(facility.timezone)))}>
                  {t("common_today")}
                </Button>
              </div>
            ) : null}
          </div>
          <div className="flex-1 flex items-center justify-end">
            <ScanIdModal onSuccess={setScanned} facilityId={facilityId} />
            <NewBookingModal initialData={scanned} language={facility.defaultLanguage}>
              <Button className="hidden sm:block">{t("bookings_add_button", "New booking")}</Button>
            </NewBookingModal>
          </div>
        </div>
        <div className="mt-3 flex">
          <div className="flex flex-wrap gap-3">
            <BookingStatusFilter filter={filter} />
          </div>
          <div className="ml-auto hidden sm:block">
            <BookingsDashboardSettings />
          </div>
        </div>
      </div>
      {bookings.total ? (
        <BookingsMultiDayList bookings={bookings} facilityId={facilityId} />
      ) : bookings.isLoading ? (
        <CentralSpinner />
      ) : (
        <div className="text-muted-foreground text-center p-12">
          {t("bookings_list_empty", "No bookings found after {{date}}", { date: formatDate(filter.date, "date") })}
        </div>
      )}
    </div>
  );
}

function BookingStatusFilter({ filter }: { filter: BookingsFilter }) {
  const { t } = useTranslation();

  const stats = useBookingStats({ ...filter });

  const skippedStatusesFilters: Partial<Record<ApiObjects["BookingDto"]["status"], boolean>> = {
    ON_HOLD: !filter.statuses.ON_HOLD,
    CANCELLED: !filter.statuses.CANCELLED,
  };
  const alwaysShownFilters: Partial<Record<ApiObjects["BookingDto"]["status"], boolean>> = {
    PENDING: true,
    IN_PROGRESS: true,
    COMPLETED: true,
  };
  const hasSkippedStatuses = Object.entries(skippedStatusesFilters).some(
    ([status, skipped]) => skipped && stats[status as ApiObjects["BookingDto"]["status"]]
  );
  const [collapseSkippedFilters, setCollapseSkippedFilters] = useState(true);

  const STATUSES = getBookingStatusLabels(t);

  return (
    <>
      {(Object.entries(STATUSES) as Entries<typeof STATUSES>)
        .filter(([status]) => alwaysShownFilters[status] || stats[status])
        .filter(([status]) => !collapseSkippedFilters || !skippedStatusesFilters[status])
        .map(([status, label]) => (
          <Button
            key={status}
            variant="outline"
            className={cn("flex items-center justify-start gap-1", BOOKING_STATUS_CLASS_NAME[status])}
            onClick={() => filter.toggleStatus(status)}
          >
            <input type="checkbox" checked={filter.statuses[status]} onChange={() => 0} />
            {label} ({status ? stats[status] : Object.values(stats).reduce((sum, s) => sum + s, 0)})
          </Button>
        ))}
      {hasSkippedStatuses && (
        <TooltipSimple text={t("bookings_collapsedStatusFilters_revealButton_tooltip", "Show all statuses")}>
          <Button
            variant="link"
            className="px-0 text-foreground"
            onClick={() => setCollapseSkippedFilters(!collapseSkippedFilters)}
          >
            {collapseSkippedFilters ? <Ellipsis strokeWidth={1.2} /> : <X strokeWidth={1.2} />}
          </Button>
        </TooltipSimple>
      )}
    </>
  );
}
