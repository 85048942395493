import { useOpeningHours } from "@/api/useOpeningHours";
import { Card, CardContent } from "@/components/ui/card";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { Form, FormField } from "@/components/ui/form";
import { useAuth } from "@/lib/useAuth";
import { zodResolver } from "@hookform/resolvers/zod";
import { Fragment, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { TimeRangeInput } from "./TimeRangeInput";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { useOpeningHoursUpdate } from "@/api/useOpeningHoursUpdate";
import { DatePicker } from "@/components/ui/date-picker";
import { Trash2Icon } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { formatDateTnLocalTimezone, zonedNow } from "@pulso/utils";

export function OpeningHoursPage() {
  const { facilityId, facility } = useAuth();
  const { openingHours, isLoading } = useOpeningHours(facilityId);
  const update = useOpeningHoursUpdate(facilityId);
  const { t } = useTranslation();

  const DAYS_LABEL = [
    t("common_date_monday", "Monday"),
    t("common_date_tuesday", "Tuesday"),
    t("common_date_wednesday", "Wednesday"),
    t("common_date_thursday", "Thursday"),
    t("common_date_friday", "Friday"),
    t("common_date_saturday", "Saturday"),
    t("common_date_sunday", "Sunday"),
  ];

  const formSchema = z.object({
    days: z.array(
      z.object({
        from: z.number(),
        to: z.number(),
        isOpen: z.boolean(),
      })
    ),
    exceptions: z.array(
      z.object({
        from: z.number(),
        to: z.number(),
        date: z.string(),
      })
    ),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: openingHours || { days: [] },
  });
  const { fields: dayFields } = useFieldArray({ control: form.control, name: "days" });
  const {
    fields: exceptions,
    append: addException,
    remove: removeException,
  } = useFieldArray({ control: form.control, name: "exceptions" });

  useEffect(() => {
    if (openingHours) {
      form.setValue("days", openingHours.days);
      form.setValue("exceptions", openingHours.exceptions);
    }
  }, [openingHours, form]);

  if (isLoading) {
    return <CentralSpinner />;
  }

  return (
    <Card>
      <CardContent className="pt-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit((values) => update.mutate(values))}>
            <div className="flex flex-col overflow-x-auto space-y-12 lg:space-y-0 lg:flex-row">
              <div>
                <div className="mb-6">{t("settings_openingHours_title", "Opening hours")}</div>
                <div className="grid gap-3 mr-6" style={{ gridTemplateColumns: "min-content auto" }}>
                  {dayFields.map((dayField, day) => (
                    <Fragment key={dayField.id}>
                      <div className="flex items-center">{DAYS_LABEL[day]}</div>
                      <FormField
                        name={`days.${day}`}
                        control={form.control}
                        render={({ field }) => (
                          <>
                            <div className="flex items-center space-x-3">
                              <TimeRangeInput {...field} disabled={field.disabled || !field.value.isOpen} />
                              <Switch
                                checked={field.value.isOpen}
                                onCheckedChange={(checked) => {
                                  if (!checked) {
                                    field.onChange({ ...field.value, isOpen: false });
                                  } else {
                                    field.onChange({ ...field.value, isOpen: true });
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                      ></FormField>
                    </Fragment>
                  ))}
                </div>
              </div>
              <div>
                <div className="mb-6">{t("settings_openingHoursExceptions_title", "Closed on")}</div>
                <div className="mb-3 space-y-3">
                  {exceptions.map((exceptionField, exceptionIndex) => (
                    <div className="flex space-x-3" key={exceptionField.id}>
                      <FormField
                        name={`exceptions.${exceptionIndex}.date`}
                        control={form.control}
                        render={({ field }) => (
                          <>
                            {facility && (
                              <DatePicker
                                mode="single"
                                selected={new Date(field.value)}
                                onSelect={(_, date) => field.onChange(formatDateTnLocalTimezone(date))}
                                fromDate={zonedNow(facility.timezone)}
                              />
                            )}
                          </>
                        )}
                      ></FormField>
                      <FormField
                        name={`exceptions.${exceptionIndex}`}
                        control={form.control}
                        render={({ field }) => <TimeRangeInput {...field} />}
                      ></FormField>
                      <Button size="icon" variant="ghost" type="button" onClick={() => removeException(exceptionIndex)}>
                        <Trash2Icon size={16} strokeWidth={1.2} />
                      </Button>
                    </div>
                  ))}
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  type="button"
                  onClick={() =>
                    facility &&
                    addException({ date: formatDateTnLocalTimezone(zonedNow(facility.timezone)), from: 540, to: 1080 })
                  }
                >
                  {t("settings_openingHoursExceptions_add_button", "Add closed on")}
                </Button>
              </div>
            </div>

            <Button className="mt-6" type="submit">
              {t("common_button_save")}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
