import React from "react";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";
import { ChangeEvent, ForwardedRef, forwardRef, useRef } from "react";
import { Trash2 } from "lucide-react";

type FileInputProps = {
  value: File | { name: string; url: string } | null;
  onChange(file: File | null): void;
};

export const FileInput = forwardRef((props: FileInputProps, ref: ForwardedRef<HTMLDivElement>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return (
    <div ref={ref}>
      <div className="flex space-x-3 items-center flex-wrap">
        <Button type="button" variant="outline" className="bg-white" onClick={() => inputRef.current?.click()}>
          {props.value
            ? t("common_fileUpload_change_button", "Upload a new file")
            : t("common_fileUpload_upload_button", "Select a file")}
        </Button>
        <div className="flex items-center space-x-3 text-sm overflow-hidden">
          {props.value && "url" in props.value ? (
            <a href={props.value.url} target="_blank" className="flex-1 overflow-hidden text-ellipsis">
              {props.value?.name}
            </a>
          ) : (
            <span className="flex-1 overflow-hidden text-ellipsis">{props.value?.name}</span>
          )}
          {props.value ? (
            <Button
              variant="link"
              className="pl-0 flex-shrink-0"
              onClick={() => {
                props.onChange(null);
              }}
            >
              <Trash2 size={16} />
            </Button>
          ) : null}
        </div>
      </div>
      <input type="file" ref={inputRef} className="hidden" onChange={onChange} accept="image/*;capture=camera" />
    </div>
  );

  function onChange(event: ChangeEvent) {
    const file = inputRef.current?.files?.[0];

    if (file) {
      props.onChange(file);
    }
  }
});
