import { api } from "@/lib/api-client";
import { useMutationWithAuth } from "@/lib/useMutationWithAuth";
import { ApiObjects } from "@pulso/api-client";

export function useBookingCreate() {
  return useMutationWithAuth({
    mutationFn: async (facilityId: string, data: ApiObjects["CreateBookingBody"]) => {
      return await api.createBooking(
        {
          facilityId,
        },
        data,
        { withFiles: true }
      );
    },
    invalidateKeys: [["bookings"]],
    successMessage: "SAVE_SUCCESS",
  });
}
