"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zonedNow = zonedNow;
exports.isZonedToday = isZonedToday;
exports.zonedStartOfDay = zonedStartOfDay;
exports.zonedEndOfDay = zonedEndOfDay;
exports.getTimezoneOffset = getTimezoneOffset;
exports.getDstDiff = getDstDiff;
exports.utcToZonedTime = utcToZonedTime;
exports.zonedTimeToUtc = zonedTimeToUtc;
exports.formatDate = formatDate;
exports.parseTime = parseTime;
exports.setTime = setTime;
exports.getNextTimeSlot = getNextTimeSlot;
exports.formatDateTnLocalTimezone = formatDateTnLocalTimezone;
exports.pad = pad;
const date_fns_1 = require("date-fns");
const FORMATTERS = {
    "date-time": (locale) => Intl.DateTimeFormat(locale, { dateStyle: "medium", timeStyle: "short" }),
    date: (locale) => Intl.DateTimeFormat(locale, { dateStyle: "medium" }),
    "date-short": (locale) => Intl.DateTimeFormat(locale, { month: "2-digit", day: "2-digit" }),
    time: (locale) => Intl.DateTimeFormat(locale, { timeStyle: "short" }),
};
function zonedNow(timeZone) {
    const localTime = new Date();
    const utcTime = (0, date_fns_1.add)(localTime, { minutes: localTime.getTimezoneOffset() });
    return utcToZonedTime(utcTime, timeZone);
}
function isZonedToday(date, timeZone) {
    return (0, date_fns_1.isSameDay)(zonedNow(timeZone), date);
}
function zonedStartOfDay(timeZone) {
    const zonedSod = (0, date_fns_1.startOfDay)(zonedNow(timeZone));
    return zonedTimeToUtc(zonedSod, timeZone);
}
function zonedEndOfDay(timeZone) {
    const zonedSod = (0, date_fns_1.endOfDay)(zonedNow(timeZone));
    return zonedTimeToUtc(zonedSod, timeZone);
}
function getTimezoneOffset(dt, timeZone) {
    const date = new Date(dt);
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
}
function getDstDiff(date1, date2, timeZone) {
    return getTimezoneOffset(date1, timeZone) - getTimezoneOffset(date2, timeZone);
}
function utcToZonedTime(date, timeZone) {
    return (0, date_fns_1.add)(date, { minutes: getTimezoneOffset(date, timeZone) });
}
function zonedTimeToUtc(date, timeZone) {
    return (0, date_fns_1.add)(date, { minutes: -getTimezoneOffset(date, timeZone) });
}
function formatDate(date, timezone, locale, format = "date-time") {
    const zoned = timezone ? utcToZonedTime(date, timezone) : new Date(date);
    return FORMATTERS[format](locale).format(zoned);
}
function parseTime(mins) {
    const months = Math.floor(mins / (30 * 1440));
    mins %= 30 * 1440;
    const weeks = Math.floor(mins / (7 * 1440));
    mins %= 7 * 1440;
    const days = Math.floor(mins / 1440);
    mins %= 1440;
    const hours = Math.floor(mins / 60);
    mins %= 60;
    const minutes = mins;
    return {
        months,
        weeks,
        days,
        hours,
        minutes,
    };
}
function setTime(date, timeInMinutes) {
    const { hours, minutes } = parseTime(timeInMinutes);
    const newDate = (0, date_fns_1.setHours)((0, date_fns_1.setMinutes)(date, minutes), hours);
    newDate.setMilliseconds(0);
    newDate.setSeconds(0);
    return newDate;
}
function getNextTimeSlot(granularity, from) {
    if (granularity <= 30) {
        return (0, date_fns_1.roundToNearestMinutes)(from, { nearestTo: granularity, roundingMethod: "ceil" });
    }
    return (0, date_fns_1.roundToNearestHours)(from, { roundingMethod: "ceil" });
}
function formatDateTnLocalTimezone(date) {
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
}
function pad(n) {
    return n > 9 ? n + "" : "0" + n;
}
