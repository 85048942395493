import { CentralSpinner } from "@/components/ui/central-spinner";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { useFacilitySettings } from "@/api/useFacilitySettings";
import { useFacilitySettingsPatch } from "@/api/useFacilitySettingsPatch";

export function BookingStatusesForm({ facilityId }: { facilityId: string }) {
  const update = useFacilitySettingsPatch(facilityId);
  const { settings } = useFacilitySettings(facilityId);
  const { t } = useTranslation();

  const formSchema = z.object({
    reviewEnabled: z.boolean(),
    onHoldEnabled: z.boolean(),
  });

  const form = createForm(
    {
      reviewEnabled: {
        label: t("settings_facility_form_reviewEnabled_title", "Booking reviews"),
        type: "switch",
        placeholder: t("settings_facility_form_reviewEnabled_label", "Enable booking reviews"),
        info: "Enable the Review booking status if sometimes you need to verify bookings before they are confirmed. Bookings in review will affect the availability.",
      },
      onHoldEnabled: {
        label: t("settings_facility_form_onHoldEnabled_title", "On-hold bookings"),
        type: "switch",
        placeholder: t("settings_facility_form_onHoldEnabled_label", "Enable on-hold bookings"),
        info: "Enable a the On Hold booking status if sometimes it is unclear what is supposed to happen with a booking. Once you know what to do, you can confirm or cancel the booking. On Hold bookings will not affect the availability.",
      },
    },
    formSchema
  );

  if (!settings) {
    return <CentralSpinner />;
  }

  return (
    <PulsoFormProvider
      {...form}
      isLoading={update.isPending}
      initialValues={{
        reviewEnabled: settings.reviewEnabled,
        onHoldEnabled: settings.onHoldEnabled,
      }}
      onSubmit={(values) => update.mutate(values)}
    />
  );
}
