import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ApiObjects } from "@pulso/api-client";
import { PropsWithChildren, useState } from "react";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { useRentalPeriods } from "@/api/useRentalPeriods";
import { cn } from "@pulso/components/lib/utils";
import { Period } from "@/components/specific/Period";
import { ArrowLeft } from "lucide-react";
import { useBookingItemsCreate } from "@/api/useBookingItemsCreate";
import { ButtonLoadable } from "@/components/ui/button-loadable";
import { Link } from "react-router-dom";
import { useProductsVariants } from "@/api/useProductsVariants";
import sum from "lodash/sum";
import keyBy from "lodash/keyBy";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import { Badge } from "@/components/ui/badge";
import { InfoBox } from "@/components/specific/InfoBox";
import { TooltipSimple } from "@/components/ui/tooltip";
import { Warning } from "@/components/specific/Warning";
import { useMe } from "@/api/useMe";
import { NewBookingItemModal } from "./NewBookingItemModal";
import { useTranslation } from "react-i18next";

type NewBookingItemModalProps = PropsWithChildren<{ booking: ApiObjects["BookingDto"]; periodId?: string }>;

export function AddBookingItemModal({ booking, periodId, children }: NewBookingItemModalProps) {
  const { t } = useTranslation();
  const { user } = useMe();

  if (user?.featureFlags.newBookingItemModal === "multi") {
    return (
      <AddBookingItemModalInternal booking={booking} periodId={periodId}>
        <Button>{t("bookings_items_add_button", "Add products")}</Button>
      </AddBookingItemModalInternal>
    );
  }

  return (
    <NewBookingItemModal booking={booking}>
      <Button>{t("bookings_items_add_button", "Add products")}</Button>
    </NewBookingItemModal>
  );
}

export function AddBookingItemModalInternal({ booking, periodId, children }: NewBookingItemModalProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState<"product" | "period">("product");
  const [selectedPeriodId, setSelectedPeriodId] = useState(periodId);
  const [selectedProducts, setSelectedProducts] = useState<Record<string, number>>({});
  const { variants, isLoading } = useProductsVariants(booking.facilityId);
  const { periods } = useRentalPeriods();
  const hasProducts = Object.values(selectedProducts).some(Boolean);
  const hasPeriod = Boolean(selectedPeriodId);
  const canMoveForward = step === "product" ? hasProducts : hasPeriod;
  const availablePeriodIds = keyBy(
    variants
      .filter((variant) => !hasProducts || selectedProducts[`${variant.productId}_${variant.variantId}`])
      .reduce(
        (periods, variant) => intersection(periods, Object.keys(variant.periods)),
        periods.map((p) => p.id)
      )
  );
  const addBookingItems = useBookingItemsCreate(booking.id);

  const isWide = variants.length > 10;

  if (isLoading) {
    return <CentralSpinner />;
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent size={isWide ? "4xl" : "lg"}>
        <DialogHeader>
          <DialogTitle>{t("bookings_items_create_product_title", "Select a product")}</DialogTitle>
          <DialogDescription>
            {t("bookings_items_create_product_subtitle", "Which product would the customer want")}
          </DialogDescription>
        </DialogHeader>
        <div className="overflow-y-auto h-[600px]">
          {step === "product" && (
            <div className={cn("grid gap-3", isWide ? "grid-cols-1: md:grid-cols-2" : "grid-cols-1")}>
              {variants.map((variant) => (
                <div
                  key={variant.variantId}
                  className={cn(
                    "flex items-center gap-3 overflow-hidden",
                    isProductAvailable(variant.periods) && "group"
                  )}
                >
                  <div
                    className={cn(
                      "p-3 h-full border rounded-xl flex items-center gap-2 flex-1 group-hover:bg-secondary-light cursor-pointer",
                      selectedProducts[`${variant.productId}_${variant.variantId}`] && "bg-secondary"
                    )}
                    onClick={() =>
                      isProductAvailable(variant.periods) && updateProduct(variant.productId, variant.variantId, 1)
                    }
                  >
                    <div
                      className={cn(
                        "flex items-center gap-2 flex-1",
                        !isProductAvailable(variant.periods) && "opacity-50"
                      )}
                    >
                      <div
                        className="h-9 w-9 bg-cover bg-center rounded-xl"
                        style={{ backgroundImage: `url("${variant.thumbUrl}")` }}
                      ></div>
                      <span className="text-sm flex items-center gap-1">
                        <span>{variant.name}</span>
                        {!isProductAvailable(variant.periods) && (
                          <>
                            {!isEmpty(variant.periods) ? (
                              <span className="text-muted-foreground text-xs ml-auto">
                                <InfoBox
                                  text={t(
                                    "bookings_details_addProduct_incompatible",
                                    "This product doesn't share periods with already selected products. If you need to add products for different periods, please add them one by one."
                                  )}
                                />
                              </span>
                            ) : (
                              <span className="text-xs ml-auto">
                                <TooltipSimple
                                  text={t(
                                    "bookings_details_addProduct_unavailable",
                                    "This product has no prices defined and can't be rented out. Click to define prices."
                                  )}
                                >
                                  <Link to={`../products/${variant.productId}`}>
                                    <Warning className="text-warn" />
                                  </Link>
                                </TooltipSimple>
                              </span>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    {Object.keys(variant.periods).length === 0 && (
                      <div>
                        <Link to={`../products/${variant.productId}`}>
                          <Button variant="link" size="sm">
                            {t("bookings_items_create_product_noPeriodsEnabled_button", "Set prices")}
                          </Button>
                        </Link>
                      </div>
                    )}
                    {selectedProducts[`${variant.productId}_${variant.variantId}`] > 0 && (
                      <div className="flex items-center gap-3">
                        <div>
                          <Button
                            variant="outline"
                            onClick={(e) => {
                              e.stopPropagation();
                              updateProduct(variant.productId, variant.variantId, -1);
                            }}
                          >
                            -
                          </Button>
                        </div>
                        <div>{selectedProducts[`${variant.productId}_${variant.variantId}`] || 0}</div>
                        <div>
                          <Button
                            variant="outline"
                            onClick={(e) => {
                              e.stopPropagation();
                              updateProduct(variant.productId, variant.variantId, 1);
                            }}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {step === "period" && (
            <div className="flex flex-col gap-3">
              <div className="flex flex-wrap gap-3 pb-3">
                {getSelectedProducts().map((item) => (
                  <Badge variant="secondary" key={item.variant.variantId} className="">
                    {item.count} x {item.variant.name}
                  </Badge>
                ))}
                <Button size="xs" variant="link" onClick={() => setStep("product")}>
                  {t("bookings_details_addProduct_changeProducts_button", "Add / Remove")}
                </Button>
              </div>
              <div className="pt-3 pb-1">Choose a period</div>
              {periods
                .filter((p) => availablePeriodIds[p.id])
                .map((period) => (
                  <div
                    key={period.id}
                    onClick={() => onPeriodSelected(period.id)}
                    className={cn(
                      "p-3 border rounded-xl flex items-center gap-3 cursor-pointer",
                      selectedPeriodId === period.id && "bg-secondary"
                    )}
                  >
                    <input type="radio" checked={selectedPeriodId === period.id} onChange={() => 0} />
                    <span>{period.name}</span>
                    <span className="text-xs text-muted-foreground">
                      (<Period minutes={period.period} />)
                    </span>
                  </div>
                ))}
              {periods.length - Object.keys(availablePeriodIds).length > 0 && (
                <div className="text-muted-foreground text-xs py-1 px-4">
                  {t(
                    "bookings_details_addProduct_unavailablePeriodsCount_label",
                    "* {{count}} periods are unavailable with the seleceted products",
                    {
                      count: periods.length - Object.keys(availablePeriodIds).length,
                    }
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <DialogFooter className="shadow-top -mx-6 pt-6 px-6 items-center">
          <div className="hidden sm:block flex-1 text-sm">
            {t("bookings_details_addProduct_productSelected_label", "{{productsCount}} products selected", {
              productsCount: sum(Object.values(selectedProducts)),
            })}
          </div>
          <div className="flex justify-between w-full sm:justify-start sm:w-auto">
            {step === "period" && (
              <Button variant="link" className="flex items-center" onClick={() => setStep("product")}>
                <ArrowLeft size={14} /> {t("bookings_details_addProduct_backToProducts_button", "Select products")}
              </Button>
            )}
            <ButtonLoadable
              className="px-12"
              onClick={() => onSave()}
              disabled={!canMoveForward}
              isLoading={addBookingItems.isPending}
            >
              {step === "product"
                ? t("bookings_details_addProduct_choosePeriod_button", "Choose period")
                : t("common_button_save", "Save")}
            </ButtonLoadable>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  function updateProduct(productId: string, variantId: string, count: number) {
    setSelectedProducts({
      ...selectedProducts,
      [`${productId}_${variantId}`]: Math.max((selectedProducts[`${productId}_${variantId}`] || 0) + count, 0),
    });
  }

  function onPeriodSelected(periodId: string) {
    if (isPeriodAvailable(periodId)) {
      setSelectedPeriodId(periodId);
    }
  }

  function onSave() {
    if (step === "product") {
      setStep("period");
      if (periodId) {
        setSelectedPeriodId(periodId);
      } else {
        const periodIds = Object.keys(availablePeriodIds);
        if (periodIds.length > 0) {
          setSelectedPeriodId(periodIds[0]);
        }
      }
    } else {
      if (selectedPeriodId && hasProducts) {
        save(selectedPeriodId);
      } else {
        setStep("product");
      }
    }
  }

  function save(periodId: string) {
    const items: ApiObjects["AddItemsToBookingBody"]["items"] = [];
    for (const [productVariantKey, count] of Object.entries(selectedProducts)) {
      const [productId, variantId] = productVariantKey.split("_");
      for (let i = 0; i < count; i++) {
        items.push({
          periodId,
          productId,
          variantId,
        });
      }
    }
    return addBookingItems.mutate({ items }, { onSuccess: () => setOpen(false) });
  }

  function onOpenChange(isOpen: boolean) {
    setSelectedPeriodId(periodId);
    setStep("product");
    setSelectedProducts({});
    setOpen(isOpen);
  }

  function isProductCompatible(productPeriods: Record<string, string>) {
    return (
      Object.keys(availablePeriodIds).length === 0 || Object.keys(availablePeriodIds).some((id) => productPeriods[id])
    );
  }

  function isProductAvailable(productPeriods: Record<string, string>) {
    return Object.keys(productPeriods).length > 0 && isProductCompatible(productPeriods);
  }

  function isPeriodAvailable(periodId: string) {
    return Object.keys(availablePeriodIds).length === 0 || availablePeriodIds[periodId];
  }

  function getSelectedProducts() {
    return Object.keys(selectedProducts)
      .filter((productVariantKey) => selectedProducts[productVariantKey])
      .map((productVariantKey) => {
        const [_, variantId] = productVariantKey.split("_");
        const variant = variants.find((v) => v.variantId === variantId)!;
        return { variant, count: selectedProducts[productVariantKey] };
      });
  }
}
