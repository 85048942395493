import { useBookingAddPenalty } from "@/api/useBookingAddPenalty";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { t } from "i18next";
import { PropsWithChildren, useState } from "react";
import { z } from "zod";
import { useExtraCharges } from "@/api/useExtraCharges";

type AddPenaltyModalProps = PropsWithChildren<{
  facilityId: string;
  bookingId: string;
  currency: string;
}>;

export function AddPenaltyModal({ facilityId, bookingId, currency, children }: AddPenaltyModalProps) {
  const [open, setOpen] = useState(false);
  const { extraCharges } = useExtraCharges(facilityId);

  const formSchema = z.object({
    amount: z.number(),
    reason: z.string(),
    notes: z.string().optional(),
    vat: z.any(),
  });

  const form = createForm(
    {
      reason: {
        label: t("bookings_invoice_penalties_form_label_reason", "Reason"),
        type: "select",
        placeholder: "",
        options: [
          ...extraCharges
            .filter((c) => c.defaultAmount >= 0)
            .map((extraCharge) => ({
              label: extraCharge.concept,
              value: extraCharge.concept,
            })),
          { label: t("bookings_invoice_penalties_form_option_other", "Other"), value: "OTHER" },
        ],
      },
      amount: {
        label: t("bookings_invoice_penalties_form_label_amount", "Amount"),
        type: "price",
        sign: currency,
        disableIf: (values) => !values.reason,
        className: "w-32",
      },
      vat: {
        label: t("bookings_invoice_penalties_form_label_vat", "VAT"),
        type: "number",
        prefix: "%",
        readOnly: true,
        className: "w-32",
      },
      notes: {
        label: t("bookings_invoice_penalties_form_label_notes", "Notes"),
        type: "text",
      },
    },
    formSchema
  );

  const add = useBookingAddPenalty(bookingId);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("bookings_penalties_form_title", "Add penalty")}</DialogTitle>
          <DialogDescription>
            {t("bookings_penalties_form_subtitle", "Add penalty to the booking which the customer needs to pay")}
          </DialogDescription>
        </DialogHeader>
        <PulsoFormProvider
          {...form}
          isLoading={add.isPending}
          onSubmit={(values) => add.mutate(values, { onSuccess: () => setOpen(false) })}
          onChange={(values, info, form) => {
            if (info.name === "reason") {
              if (values.reason !== "OTHER") {
                const extraCharge = extraCharges.find((c) => c.concept === values.reason);
                const newAmount = extraCharge?.defaultAmount;
                if (typeof newAmount === "number") {
                  form.setValue("amount", newAmount);
                }

                const vat = extraCharge?.vat;
                if (typeof vat === "number") {
                  form.setValue("vat", vat);
                }
              } else {
                form.setValue("amount", 0);
                form.setValue("vat", 21);
              }
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
