"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MailType = void 0;
exports.MailType = {
    DeliveryMail: "DeliveryMail",
    SingleSignature: "SingleSignature",
    BookingConfirmed: "BookingConfirmed",
    BookingCreated: "BookingCreated",
    BookingReceived: "BookingReceived",
    BookingCancelled: "BookingCancelled",
    CreatePassword: "CreatePassword",
    ResetPassword: "ResetPassword",
};
